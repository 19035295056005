<!-- 视频创建框 -->
<template>
  <div>
    <el-dialog
      :title="dialogTatle?dialogTatle:'导入视频'"
      :visible.sync="show_new_subject"
      width="40%"
      :before-close="handleClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="选择年级：" prop="gradeValue">
          <el-select
            v-model="ruleForm.gradeValue"
            placeholder="请选择年级"
            size="mini"
            @change="changeSearch"
          >
            <el-option
              v-for="item in gradeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择学科：" prop="regionSubject">
          <el-select
            v-model="ruleForm.regionSubject"
            placeholder="请选择学科"
            size="mini"
            @change="changeSearch"
          >
            <el-option
              v-for="item in subjectIdOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择章节：" prop="chapter">
          <el-select v-model="ruleForm.chapter" placeholder="请选择学科" size="mini">
            <el-option
              v-for="item in chapterOptions"
              :key="item.id"
              :label="item.chapterTitle"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="视频名称：" prop="name">
          <el-input v-model="ruleForm.name" size="mini" maxlength="20" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="视频备注：" prop="remarks">
          <el-input v-model="ruleForm.remarks" size="mini" maxlength="20" show-word-limit></el-input>
        </el-form-item>
        <el-upload
          ref="upload"
          class="upload-demo"
          action
          accept=".mp4"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :http-request="handleUpload"
          :before-upload="beforeAvatarUpload"
          multiple
          :limit="1"
          :on-exceed="handleExceed"
          :file-list="fileList"
          :auto-upload="true"
        >
          <el-button
            size="small"
            type="primary"
            :loading="show_load"
          >{{show_load?'上传中':uploadTitle}}</el-button>
          <div slot="tip" class="el-upload__tip">只能上传.mp4文件</div>
        </el-upload>
        <el-progress v-if="showProgress" :percentage="progress" :color="customColors"></el-progress>
        <el-divider></el-divider>
        <el-form-item label="观看指引：">
          <el-table :data="guideData" style="width: 100%;margin-top:20px" border>
            <el-table-column prop="time" label="时间点" width="70"></el-table-column>
            <el-table-column prop="remark" label="说明内容"></el-table-column>
            <el-table-column label="操作" width="150">
              <template slot-scope="scope">
                <el-button type="primary" size="mini" @click="clickAddGuide(1,scope.row)">编辑</el-button>
                <el-button type="danger" size="mini" @click="clickAddGuide(2,scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-button
            type="primary"
            size="mini"
            class="add_guide"
            @click="clickAddGuide(0)"
            style="width:100%"
            :disabled="tempUrl==''"
          >新增观看指引</el-button>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="show_new_subject = false">取 消</el-button>
        <el-button
          type="primary"
          @click="new_subject('ruleForm',dialogTatle)"
          :disabled="show_load"
        >确 定</el-button>
      </span>
      <el-dialog
        :title="insideTitle"
        :visible.sync="showAddGuide"
        width="600px"
        :before-close="dialogBeforeClose"
        append-to-body
      >
        <div>
          <el-form
            :model="insideRuleForm"
            :rules="insideRules"
            ref="insideRuleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <div>
              <el-form-item label="时间点" prop="time" style="display:inline-block">
                <el-input v-model="insideRuleForm.time"></el-input>
              </el-form-item>
              <el-popover
                placement="bottom"
                title="小提示"
                width="200"
                trigger="click"
                content="时间点不可以超过视频的最大时间，输入的格式为(分钟:秒)，如:03:12(三分十二秒)"
                style="height:150px"
              >
                <span
                  slot="reference"
                  class="span_tip el-icon-question"
                  style="display:inline-block;margin-left:10px;"
                ></span>
              </el-popover>
            </div>
            <el-form-item prop="desc" label="内容">
              <el-input
                type="textarea"
                v-model="insideRuleForm.desc"
                maxlength="50"
                show-word-limit
                style="resize:none"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer">
          <el-button @click="AddGuide(1)">取 消</el-button>
          <el-button type="primary" @click="AddGuide(0,'insideRuleForm')">确 定</el-button>
        </div>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import { client } from '../../../components/AliOss'
import { videoAdd, getSpecialtopic, videoUpdate } from '../../../api/api'
export default {
  props: {
    showDialog: {
      type: Boolean
    },
    parameterSet: {
      type: Object
    }
  },
  data () {
    return {
      show_new_subject: false,
      ruleForm: {
        name: '',
        remarks: '',
        regionSubject: '',
        gradeValue: '',
        chapter: ''
      },
      rules: {
        gradeValue: [
          { required: true, message: '请选择年级', trigger: 'change' }
        ],
        regionSubject: [
          { required: true, message: '请选择学科', trigger: 'change' }
        ],
        chapter: [
          { required: true, message: '请选择章节', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入题目名称', trigger: 'blur' },
        ],
        remarks: [
          { required: true, message: '请输入题目备注', trigger: 'blur' },
        ]
      },
      insideRuleForm: {
        id: null,
        time: '',
        desc: ''
      },
      insideRules: {
        time: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
        ],
        desc: [
          { required: true, message: '请填写活动形式', trigger: 'blur' }
        ]
      },
      subjectIdOptions: this.parameter.subject(),
      gradeOptions: this.parameter.grade(),
      chapterOptions: [],//章节列表
      fileList: [],
      fileName: '',//上传的文件名字
      show_load: false,
      guideData: [],//观看指引
      showAddGuide: false,//新增指引弹框
      insideTitle: '新建观看指引',//内层标题
      showProgress: false,//进度条显示
      progress: 0,//进度条
      customColors: [//进度条渐变色
        { color: '#f56c6c', percentage: 20 },
        { color: '#e6a23c', percentage: 40 },
        { color: '#5cb87a', percentage: 60 },
        { color: '#1989fa', percentage: 80 },
        { color: '#67c23a', percentage: 100 }
      ],
      tempUrl: '',//上传视频路径
      tempSize: '',//视频时长
      dialogTatle: '',//弹框标题
      uploadTitle: '点击上传',//上传文字
      videoId: '',//编辑时的视频id
      second: '',//视频秒数
    }
  },
  watch: {
    showDialog (val, old) {
      if (val !== old) {
        this.ruleForm = {
          name: '',
          remarks: '',
          regionSubject: null,
          gradeValue: null,
          chapter: ''
        }
        this.dialogTatle = '导入视频'
        this.uploadTitle = '上传视频'
        this.tempUrl = ''
        this.guideData = []
        this.fileList = []
        this.show_new_subject = val
      }
    },
    show_new_subject (val, old) {
      if (val !== old) {
        this.hideDialog()
      }
    },
    parameterSet (val, old) {
      if (val !== old) {
        this.videoId = val.id
        this.dialogTatle = '编辑视频'
        this.uploadTitle = '更新视频'
        this.tempUrl = val.url
        this.ruleForm = {
          name: val.name,
          remarks: val.remark,
          regionSubject: val.subject,
          gradeValue: val.grade,
          chapter: val.specialTopicId
        }
        this.fileList.push({ name: val.fileName })
        this.guideData = val.list
        this.loadSpecialtopic()
      }
    }
  },
  components: {},
  mounted () {
  },
  methods: {
    // 搜索选中
    changeSearch (type) {
      this.ruleForm.chapter = ''
      if (this.ruleForm.regionSubject && this.ruleForm.gradeValue) {
        getSpecialtopic({
          grade: this.ruleForm.gradeValue,
          specialtopicType: 0,
          subject: this.ruleForm.regionSubject
        }).then(res => {
          this.chapterOptions = res.data
        })
      }
    },
    // 加载章节
    loadSpecialtopic () {
      getSpecialtopic({
        grade: this.ruleForm.gradeValue,
        specialtopicType: 0,
        subject: this.ruleForm.regionSubject
      }).then(res => {
        this.chapterOptions = res.data
      })
    },
    // 确认新建指引
    AddGuide (type, formName) {
      // 0 新建 1 取消
      if (type == 0) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let arr = this.insideRuleForm.time.split('：')
            let finalArr = null
            if (arr.length > 1) {//说明输入的中文  ：
              finalArr = arr.join(':')
            }
            if (finalArr) {
              this.insideRuleForm.time = finalArr
            }
            let formatArr = this.insideRuleForm.time.split(':')
            // 判断时间格式是否是 00：00
            if (formatArr.length !== 2 || formatArr[0].length !== 2 || formatArr[1].length !== 2) {
              this.$message.error('时间格式不正确！')
              return
            }
            let guideSecond = this.duration.ms_to_s(this.insideRuleForm.time)
            if (guideSecond > this.second) {
              this.$message.error('指引时间不可以大于视频时长！')
              return
            }
            // id存在则是编辑
            if (this.insideRuleForm.id) {
              this.guideData.map(item => {
                if (item.id == this.insideRuleForm.id) {
                  item.time = this.insideRuleForm.time
                  item.remark = this.insideRuleForm.desc
                }
              })
            } else {
              this.guideData.push({
                id: this.guideData.length + 1,
                time: this.insideRuleForm.time,
                remark: this.insideRuleForm.desc
              })
            }
            this.insideRuleForm = {
              id: null,
              time: '',
              desc: ''
            }
            this.showAddGuide = false
          } else {
            return false;
          }
        });
      } else if (type == 1) {
        this.insideRuleForm = {
          id: null,
          time: '',
          desc: ''
        }
        this.showAddGuide = false
      }
    },
    // 指引操作
    clickAddGuide (type, val) {
      // 0  新建  1  编辑  2  删除
      if (type == 0) {
        this.insideTitle = '新建观看指引'
        this.showAddGuide = true
      } else if (type == 1) {
        this.insideRuleForm = {
          id: val.id,
          time: val.time,
          desc: val.remark
        },
          this.insideTitle = '编辑观看指引'
        this.showAddGuide = true
      } else if (type == 2) {
        this.$confirm('此操作将永久删除该指引, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let finalArr = this.guideData.filter((item) => item.id != val.id)
          this.guideData = finalArr
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
        });
      }
    },
    // 确认导入
    new_subject (formName, title) {
      this.$refs[formName].validate((valid) => {
        // && this.tempUrl != ''
        if (valid && this.tempUrl != '') {
          // title 有值代表是编辑
          if (title == '编辑视频') {
            let param = {
              'id': this.videoId,
              "name": this.ruleForm.name,
              "url": this.tempUrl,
              "remark": this.ruleForm.remarks,
              "specialTopicId": this.ruleForm.chapter,
              "list": this.guideData,
              "second": this.second
            }
            videoUpdate(param).then(res => {
              if (res.code == 0) {
                this.$refs.upload.clearFiles()
                this.fileList = []
                this.show_new_subject = false
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            let param = {
              "name": this.ruleForm.name,
              "url": this.tempUrl,
              "remark": this.ruleForm.remarks,
              "specialTopicId": this.ruleForm.chapter,
              "list": this.guideData,
              "second": this.second,
              'fileName': this.fileName
            }
            videoAdd(param).then(res => {
              if (res.code == 0) {
                this.$refs.upload.clearFiles()
                this.fileList = []
                this.fileName = ''
                this.show_new_subject = false
              } else {
                this.$message.error(res.message)
              }
            })
          }
        } else {
          this.$message.error('请完善信息!')
          return false;
        }
      });
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    // 上传控制文件大小
    beforeAvatarUpload (file) {
      const that = this
      const isvideo = file.type === 'video/mp4';
      // const isLt5M = file.size / 1024 / 1024 < 5;
      // const isLt0K = file.size / 1024 / 1024 > 0;

      if (!isvideo) {
        this.$message.error('上传文件只能是视频！');
      }
      if (isvideo) {
        var url = URL.createObjectURL(file);
        var audioElement = new Audio(url);
        var duration;
        audioElement.addEventListener("loadedmetadata", function (_event) {
          duration = audioElement.duration; //时长为秒，小数，182.36
          that.second = duration
          that.tempSize = that.duration.s_to_ms(parseInt(duration))
        });
      }
      // if (!isLt5M || file.size == 0) {
      //   this.$message.error('上传word文档大小不能为空且不能超过 5MB!');
      // }
      // return isvideo && isLt5M && isLt0K;
      return isvideo;
    },
    // 上传文件
    handleUpload (file) {
      this.show_load = true
      const that = this
      //判断扩展名
      const tmpcnt = file.file.name.lastIndexOf(".")
      const exname = file.file.name.substring(tmpcnt + 1)
      let name = file.file.name;
      this.fileName = name
      const fileName = `${Date.parse(new Date())}/${name}`; //定义唯一的文件名
      async function multipartUpload () {
        // const fileName = that.name + file.file.uid
        //定义唯一的文件名，打印出来的uid其实就是时间戳
        //client 是第一步中的 client
        client().multipartUpload(fileName, file.file, {
          progress: function (p) {
            that.showProgress = true
            that.progress = Number((p * 100).toFixed(0))
            if (that.progress == 100) {
              that.showProgress = false
            }
          },
          partSize: 102400
        }).then(
          res => {
            that.show_load = false
            //下面是如果对返回结果再进行处理，根据项目需要
            let arr = res.res.requestUrls[0].split('uploadId=')
            that.tempUrl = arr.join('')
            // this.tempSize = (file.file.size / 1024 / 1024).toFixed(2) + 'M'
          }).catch(err => {
          })
      }
      multipartUpload()
    },
    // 二次取消
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
          // this.hideDialog()
        })
        .catch(_ => { });
    },
    handlePreview (file) {
    },
    // 删除文件
    handleRemove (file, fileList) {
      // 删除视频清空指引
      this.guideData = []
      this.tempUrl = ''
    },
    dialogBeforeClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    // 隐藏弹框
    hideDialog () {
      this.$emit('hideDialog', this.show_new_subject)
    }
  }
}

</script>
<style lang='less'>
@import '../../../style/mouse.less';
.add_guide {
  display: inline-block;
  position: relative;
  right: -50%;
  transform: translate(-50%);
}
/deep/.el-textarea__inner {
  resize: none;
}
.span_tip {
  width: 20px;
  height: 20px;
  // background-color: #000;
  border-radius: 50%;
}
.el-icon-question {
  font-size: 20px;
}
</style>
