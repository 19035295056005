<!-- 上传视频 -->
<template>
  <div style="height:100%">
    <el-card style="height:98%" v-if="!showPreview">
      <el-select v-model="gradeValue" placeholder="请选择年级" size="mini" @change="changeSearch(0)">
        <el-option
          v-for="item in gradeOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-select
        v-model="subjectValue"
        placeholder="请选择学科"
        size="mini"
        style="margin-left:15px"
        @change="changeSearch(1)"
      >
        <el-option
          v-for="item in subjectIdOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <el-select v-model="chapterValue" placeholder="请选择章节" size="mini" style="margin-left:15px">
        <el-option
          v-for="item in chapterOptions"
          :key="item.id"
          :label="item.chapterTitle"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-popover
        placement="bottom"
        title="小提示"
        width="200"
        trigger="click"
        content="查询只支持章节查询，请先按年级学科筛选章节之后再查询"
        style="height:150px"
      >
        <span
          slot="reference"
          class="span_tip el-icon-question"
          style="display:inline-block;margin-left:10px;"
        ></span>
      </el-popover>
      <el-button
        type="primary"
        style="margin-bottom:20px;margin-left:20px"
        size="mini"
        @click="navOperation(0)"
      >查询</el-button>
      <el-button type="primary" size="mini" @click="navOperation(1)">重置</el-button>
      <el-button
        type="primary"
        style="margin-bottom:20px"
        size="mini"
        class="btn_Import"
        @click="importSubject"
      >导入视频</el-button>
      <el-table :data="tableData" border style="width: 100%" stripe>
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="name" label="视频名称"></el-table-column>
        <el-table-column prop="gradeName" label="年级"></el-table-column>
        <el-table-column prop="subjectName" label="学科"></el-table-column>
        <el-table-column prop="specialTopicName" label="章节"></el-table-column>
        <el-table-column prop="creatorTime" label="更新时间"></el-table-column>
        <el-table-column prop="length" label="视频时长"></el-table-column>
        <el-table-column width="220" label="操作">
          <template slot-scope="scope">
            <el-button @click="operation(0,scope.row)" size="mini">预览</el-button>
            <el-button type="primary" @click="operation(1,scope.row)" size="mini">编辑</el-button>
            <el-button type="danger" @click="operation(2,scope.row)" size="mini">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleCurrentChange"
        :page-size="page.pageSize"
        :current-page.sync="page.pageNum"
        layout="total, prev, pager, next"
        :total="page.total"
        background
        style="margin-top:10px"
      ></el-pagination>
    </el-card>
    <!-- 导入视频弹框 -->
    <videoDialog :showDialog="showDialog" @hideDialog="hideDialog" :parameterSet="parameterSet"></videoDialog>
    <!-- 视频预览页面 -->
    <carryOutVideo
      v-if="showPreview"
      :currentContent="currentContent"
      @close="showPreview=false;currentContent={}"
    ></carryOutVideo>
  </div>
</template>

<script>
import videoDialog from './components/videoDialog.vue'
import carryOutVideo from '../../components/carryOutVideo/index.vue'
import { getSpecialtopic, videoList, videoDelete, videoDetail } from '../../api/api'
export default {
  data () {
    return {
      page: {//视频列表分页
        pageSize: 10,
        pageNum: 1,
        total: 0
      },
      tableData: [],//表格数据
      gradeValue: '',//选中的年级
      gradeOptions: this.parameter.grade(),
      subjectIdOptions: this.parameter.subject(),
      subjectValue: '',//选中的学科
      chapterOptions: [],//章节列表
      chapterValue: '',//选中的章节
      showDialog: false,//新建弹框
      showPreview: false,//展示预览页
      currentContent: {},//预览的选中内容
      navQuery: false,//是否是查询状态
      parameterSet: {},//编辑的参数集合
    }
  },

  components: {
    videoDialog,
    carryOutVideo
  },
  mounted () {
    this.vadio_list(null)
  },
  methods: {
    // 搜索选中
    changeSearch (type) {
      if (this.subjectValue && this.gradeValue) {
        getSpecialtopic({
          grade: this.gradeValue,
          specialtopicType: 0,
          subject: this.subjectValue
        }).then(res => {
          this.chapterOptions = res.data
        })
      }
    },
    // 获取视频列表数据
    vadio_list (val) {
      videoList({
        "pageNum": this.page.pageNum,
        "pageSize": this.page.pageSize,
        "specialTopicId": val//变式id
      }).then(res => {
        if (res.code == 0) {
          res.data.list.map(item => {
            item.creatorTime = this.dateFormat(item.creatorTime)
            item.gradeName = this.gradeOptions.find(i => { return i.value == item.grade }).label
            item.subjectName = this.subjectIdOptions.find(i => { return i.value == item.subject }).label
            item['length'] = this.duration.s_to_ms(item.second)
          })
          this.page.total = res.data.total
          this.tableData = res.data.list
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 导航操作
    navOperation (type) {
      // 0  查询  1  重置
      if (type == 0) {
        // 在选中年级学科的情况下查询,则查询状态变成true
        if (this.subjectValue && this.gradeValue && this.chapterValue) {
          this.navQuery = true
          this.vadio_list(this.chapterValue)
        }
      } else if (type == 1) {
        this.navQuery = false
        this.chapterValue = ''
        this.subjectValue = ''
        this.gradeValue = ''
        this.vadio_list(null)
      }
    },
    // 隐藏弹框
    hideDialog (val) {
      let id = this.chapterValue && this.navQuery ? this.chapterValue : null
      this.vadio_list(id)
      this.showDialog = val
    },
    // 操作
    operation (type, val) {
      // 0 预览  1 编辑  2 删除
      if (type == 0) {
        this.loadVideoDetail(val)
        this.showPreview = true
        setTimeout(() => {
          this.currentContent = this.parameterSet
        }, 300);
      } if (type == 1) {
        // this.parameterSet = val
        this.loadVideoDetail(val)
        this.showDialog = true
      } else if (type == 2) {
        this.$confirm('此操作将永久删除该视频, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          videoDelete([val.id]).then(res => {
            if (res.code == 0) {
              // 章节id
              let id = this.chapterValue && this.navQuery ? this.chapterValue : null
              this.vadio_list(id)
              this.$message({
                type: 'success',
                message: '删除成功!'
              });
            } else {
              this.$message.error(res.message)
            }
          })
        }).catch(() => {
        });
      }
    },
    // 视频详情
    loadVideoDetail (val) {
      videoDetail({ id: val.id }).then(res => {
        if (res.code == 0) {
          res.data.grade = val.grade
          res.data.subject = val.subject
          this.parameterSet = res.data
        }
      })
    },
    // 导入视频
    importSubject () {
      this.showDialog = true
      // this.show_new_subject = true
    },
    handleCurrentChange (val) {
      let id = this.chapterValue && this.navQuery ? this.chapterValue : null
      this.vadio_list(id)
      this.page.pageNum = val
    },
    // 转换时间
    dateFormat (time) {
      var date = new Date(time);
      var year = date.getFullYear();
      /* 在日期格式中，月份是从0开始的，因此要加0
       * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
       * */
      var month =
        date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // 拼接
      return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
    }
  }
}

</script>
<style lang='less' scoped>
@import '../../style/mouse.less';
.el-pagination {
  display: inline-block;
  position: relative;
  right: -50%;
  transform: translate(-50%);
}
</style>
