<!-- 执行视频活动 -->
<template>
  <div style="height:100%;background-color: #fff;">
    <el-scrollbar style="height: 100%">
      <el-card style="height:100%;border:none;box-shadow:none">
        <el-button icon="el-icon-back" @click="signOut" style="margin-left:10px">返回</el-button>
        <div style="font-size: 20px; margin:10px">{{currentContentData.name}}</div>
        <div style="margin:10px">{{currentContentData.remark}}</div>
        <div style="position: relative;">
          <div class="video_left">
            <video
              ref="videoPlayer"
              id="video"
              controls
              :src="url"
              poster
              controlslist="nodownload"
              @timeupdate="updateTime"
              style="width: 100%; height: 100%; object-fit: fill;border: 5px solid #000"
            ></video>
          </div>
          <div class="vadio_right">
            <div style="padding: 0 0 10px 20px;height:40%">
              <el-empty description="暂无指引" v-if="time_line.length==0"></el-empty>
              <el-scrollbar style="height: 100%" ref="elscrollbar">
                <el-timeline>
                  <el-timeline-item v-for="(item, index) in time_line" :key="index">
                    <el-alert
                      :title="item.remark"
                      :type="alert_type[item.type]"
                      :closable="false"
                      @click.native="clickAlert(item.timestamp)"
                    ></el-alert>
                  </el-timeline-item>
                </el-timeline>
              </el-scrollbar>
            </div>
            <el-divider>
              <i class="el-icon-chat-line-square"></i>
            </el-divider>
            <div class="comment_list">
              <ul style="height:100%;display:inline">
                <el-empty description="评论区" v-if="commentList.length==0" style="height:75%"></el-empty>
                <el-scrollbar style="height: 100%;" ref="elscrollbarLi">
                  <li
                    :id="`comment${index}`"
                    v-for="(item,index) in commentList"
                    :key="index"
                    class="comment"
                    @click="clickLi(item.time)"
                  >{{item.userFull+`(${item.ms})`+'：'+item.content}}</li>
                </el-scrollbar>
              </ul>
              <el-input
                v-model="commentValue"
                placeholder="快发表你的观点吧~"
                style="padding:0 10px;"
                @keyup.enter.native="replyComments"
                v-if="this.$route.path !== '/materialGather/video'"
              ></el-input>
            </div>
          </div>
        </div>
      </el-card>
    </el-scrollbar>
  </div>
</template>

<script>
import { videoCommentAdd, videoCommentList, videoDetail, videoAccomplish } from '../../api/api'
export default {
  props: {
    currentContent: {
      type: Object
    }
  },
  data () {
    return {
      carryOutType: 0,//该页面的类型 0 预览  1 执行
      currentTime: '',//播放时间
      url: '',//视频地址
      alert_type: ['error', 'success', 'info'],
      time_line: [],//时间线
      currentContentData: {},//视频详细数据
      commentList: [],//评论列表
      scroll: '',
      commentValue: '',//评论内容
      showFinishVideo: 0,//是否告知后端是否看完视频
      status: 0, //0  老师  1  学生
    }
  },
  watch: {
    currentContent (val, old) {
      if (val !== old) {
        this.url = val.url
        this.currentContentData = val
        val.list.map((item, index) => {
          item.timestamp = item.time
          item.type = 2
          item.height = 0
          if (index >= 3) {
            item.height = 65 * (index - 2)
          }
        })
        this.time_line = val.list
      }
    }
  },
  components: {},
  mounted () {
    if (this.$route.path !== '/materialGather/video') {
      this.loadVideoDetail(this.$route.query.videoId)
      this.loadCommentList()
    }
    this.analysis()
  },
  methods: {
    // 解析appIds
    analysis () {
      if (this.utils.identity("视频做题")) {
        this.status = 1;
      }
    },
    // 浏览完毕视频，告知后端
    finishVideo () {
      let param = {
        "taskId": this.$route.query.taskId,
        "classId": this.$route.query.classId,
        "activeId": this.$route.query.id
      }
      videoAccomplish(param).then(res => {
      })
    },
    // 视频详情
    loadVideoDetail (val) {
      videoDetail({ id: Number(val) }).then(res => {
        if (res.code == 0) {
          res.data.list.map((item, index) => {
            item.remark = item.remark + '(' + item.time + ')'
            item.timestamp = this.duration.ms_to_s(item.time)
            item.type = 2
            item.height = 0
            if (index >= 3) {
              item.height = 65 * (index - 2)
            }
          })
          this.url = res.data.url
          this.time_line = res.data.list
          this.currentContentData = res.data
        }
      })
    },
    // 回车发送评论
    replyComments () {
      if (this.commentValue) {
        let param = {
          "content": this.commentValue,//内容
          "time": Number(this.currentTime).toFixed(0),//时间
          "videoId": this.$route.query.videoId//视频id
        }
        videoCommentAdd(param).then(res => {
          if (res.code == 0) {
            this.commentValue = ''
            this.loadCommentList()
          } else {
            this.$message.error(res.message)
          }
        })
      } else {
        this.$message.warning('不能发送空的内容!')
      }
    },
    // 获取评论列表
    loadCommentList () {
      let param = {
        videoId: this.$route.query.videoId
      }
      videoCommentList(param).then(res => {
        if (res.code == 0) {
          res.data.map(item => {
            item.ms = this.duration.s_to_ms(Number(item.time))
          })
          this.commentList = res.data
        }
      })
    },
    // 点击时间线跳转对应时间
    clickAlert (val) {
      let video = document.getElementById("video")
      video.currentTime = val;
    },
    // 点击评论跳转
    clickLi (val) {
      let video = document.getElementById("video")
      video.currentTime = val;
    },
    // 视频更新时间
    updateTime (e) {
      var div = this.$refs['elscrollbar'].$refs['wrap']
      var Li = this.$refs['elscrollbarLi'].$refs['wrap']
      this.currentTime = e.target.currentTime
      if (this.time_line.length > 0 && this.time_line[0].type == 2 && !this.$refs.videoPlayer.paused) {
        this.time_line[0].type = 0
      }
      // this.commentList.map((item, index) => {
      //   let commentLi = document.getElementById(`comment${index}`)
      //   item.height = commentLi.offsetHeight * index
      //   if (this.currentTime >= item.time) {
      //     if (Li.scrollTop < item.height) {
      //       Li.scrollTop = item.height
      //     }
      //     item.show = true
      //   }
      // })
      if (this.currentTime >= this.currentContentData.second && this.status == 1) {//当前播放时间大于记录的视频时长
        this.showFinishVideo++
        if (this.showFinishVideo == 1) {
          this.finishVideo()
        }
      }
      this.time_line.map((item, index) => {
        if (this.time_line[index + 1]) {
          if (this.currentTime >= item.timestamp && this.currentTime < this.time_line[index + 1].timestamp) {
            item.type = 0
            div.scrollTop = item.height
            return
          }
          if (this.currentTime >= this.time_line[index + 1].timestamp) {
            item.type = 1
            return
          }
        }
        if (this.currentTime < item.timestamp) {
          item.type = 2
          return
        } else if (parseInt(this.currentTime) >= this.currentContentData.time_s) {
          item.type = 1
          return
        } else {
          item.type = 1
        }
      })
    },
    // 退出
    signOut () {
      if (this.$route.path == '/materialGather/video') {
        this.$emit('close', false)
      } else {
        this.$router.push({
          path: "/activityDetail",
          query: this.$route.query
        });
      }
    }
  }
}

</script>
<style lang='less' scoped>
@import '../../style/mouse.less';
.video_left {
  width: 70%;
}
.vadio_right {
  position: absolute;
  top: 0;
  right: -10px;
  width: 30%;
  height: 100%;
  /deep/.el-card__body {
    padding: 10px;
  }
}
.comment_list {
  position: relative;
  margin-left: 10px;
  height: 60%;
  padding: 0 0 40px 5px;
  .el-input {
    position: absolute;
    bottom: 50px;
  }
}
.comment {
  font-size: 18px;
  color: red;
  padding: 5px 0;
  word-wrap: break-word;
  word-break: break-all;
}
/deep/.el-empty {
  padding: 0px 0;
  width: 100%;
  height: 100%;
}
</style>
